exports.components = {
  "component---src-pages-account-segment-index-js": () => import("./../../../src/pages/AccountSegment/index.js" /* webpackChunkName: "component---src-pages-account-segment-index-js" */),
  "component---src-pages-error-index-js": () => import("./../../../src/pages/Error/index.js" /* webpackChunkName: "component---src-pages-error-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/Home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-not-found-404-js": () => import("./../../../src/pages/NotFound/404.js" /* webpackChunkName: "component---src-pages-not-found-404-js" */),
  "component---src-pages-proposal-steps-cellphone-index-js": () => import("./../../../src/pages/Proposal/steps/Cellphone/index.js" /* webpackChunkName: "component---src-pages-proposal-steps-cellphone-index-js" */),
  "component---src-pages-proposal-steps-document-index-js": () => import("./../../../src/pages/Proposal/steps/Document/index.js" /* webpackChunkName: "component---src-pages-proposal-steps-document-index-js" */),
  "component---src-pages-proposal-steps-email-index-js": () => import("./../../../src/pages/Proposal/steps/Email/index.js" /* webpackChunkName: "component---src-pages-proposal-steps-email-index-js" */),
  "component---src-pages-proposal-steps-name-index-js": () => import("./../../../src/pages/Proposal/steps/Name/index.js" /* webpackChunkName: "component---src-pages-proposal-steps-name-index-js" */),
  "component---src-pages-proposal-steps-otp-index-js": () => import("./../../../src/pages/Proposal/steps/OTP/index.js" /* webpackChunkName: "component---src-pages-proposal-steps-otp-index-js" */),
  "component---src-pages-redirect-index-js": () => import("./../../../src/pages/Redirect/index.js" /* webpackChunkName: "component---src-pages-redirect-index-js" */),
  "component---src-pages-unbalanced-system-index-js": () => import("./../../../src/pages/UnbalancedSystem/index.js" /* webpackChunkName: "component---src-pages-unbalanced-system-index-js" */)
}

