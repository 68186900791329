import { navigate } from 'gatsby';
import { ENV, SEGMENT_WRITE_KEY } from 'gatsby-env-variables';

import { configure } from '@mobi/ds';
// Utils
import analytics from '@mobi/libraries/analytics';
import feature from '@mobi/libraries/feature-toggle';

import { getCookieBarStyles, setCookieBarStyles } from 'utils/cookieBar';
import Datadog from 'utils/datadog';
import trackings from 'utils/trackings';

// Styles
import 'styles/main.scss';

console.log('Datadog.init: Started');
Datadog.init();
console.log('Datadog.init: Finished');

configure({
  originFonts: 'extern',
  fonts: {
    ItauText: [300, 400, 700, 900],
    ItauDisplay: [300, 700, 900],
  },
});

feature.setup({
  definitions: {
    dev: ENV === 'development',
    stg: ENV === 'staging',
    prod: ENV === 'production',
  },

  features: {
    isMockedRedirectPage: ['dev', 'stg'],
  },
});

analytics.setup({
  segmentId: SEGMENT_WRITE_KEY,
  GTMId: 'GTM-KCDNHM8',
  trackings,
  isSDKTrackings: true,
});

const redirectToSegment = (location) => {
  const params = new URLSearchParams(location.search);
  const campaign = params.get('utm_campaign');
  const organic = params.get('utm_content');

  const allowedPaths = ['/', '/abrir-conta', '/abrir-conta/'];
  const isAllowedPath = allowedPaths.includes(location.pathname);
  if (isAllowedPath) {
    const isUniclass = /uniclass/.test(campaign);
    const isOrganic = /organico_iu/.test(organic);
    const isPersonnalite = /personnalite/.test(campaign);

    if (isPersonnalite) {
      navigate(`/personnalite${location.search}`);
    }

    if (isUniclass || isOrganic) {
      navigate(`/uniclass${location.search}`);
    }
  }
};

export const onRouteUpdate = ({ location }) => {
  redirectToSegment(location);
  setCookieBarStyles(getCookieBarStyles());
};
